<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "OnlineSetting",
}
</script>

<style lang="scss" scoped>
.online-setting {
  height: auto !important;
  min-height: 100% !important;

  ::v-deep .tabs {
    width: 100%;
    height: 100% !important;
    min-height: 100% !important;
  }

  ::v-deep .tabs {
    .nav-tabs {
      margin-bottom: 0 !important;

      .active.nav-link {
        &:after {
          bottom: auto !important;
          top: 0 !important;
          height: 6px !important;
        }

        background: #FBFBFB !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid #E6E7E6 !important;
        border-bottom: none !important;
      }
    }

    .tab-content {
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 30px;
      background: #FBFBFB !important;
      border: 1px solid #E6E7E6 !important;
    }
  }
}
</style>